import React, { lazy } from 'react'

const TimeAttendanceManagementListing = lazy(() =>
  import('./TimeAttendanceManagement/TimeAttendanceManagementListing').then(
    module => ({
      default: module.TimeAttendanceManagementListing,
    })
  )
)

const PeriodicAllowanceTypeListing = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/PeriodicAllowanceType/PeriodicAllowanceTypeListing'
  ).then(module => ({
    default: module.PeriodicAllowanceTypeListing,
  }))
)

const TMSCompanySetting = lazy(() =>
  import('./TimeAttendanceManagement/CompanySetting/TMSCompanySetting').then(
    module => ({
      default: module.TMSCompanySetting,
    })
  )
)

const ShiftAssignmentForm = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/ShiftAssignment/ShiftAssignmentForm'
  ).then(module => ({
    default: module.ShiftAssignmentForm,
  }))
)

const ShiftPatternAssignmentForm = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/ShiftPatternAssignment/ShiftPatternAssignmentForm'
  ).then(module => ({
    default: module.ShiftPatternAssignmentForm,
  }))
)

const ShiftGroupAssignmentForm = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/ShiftGroupAssignment/ShiftGroupAssignmentForm'
  ).then(module => ({
    default: module.ShiftGroupAssignmentForm,
  }))
)

const TimeOffPolicyAssignmentForm = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TimeOffPolicyAssignment/TimeOffPolicyAssignmentForm'
  ).then(module => ({
    default: module.TimeOffPolicyAssignmentForm,
  }))
)

const OvertimeReasonCodesForm = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/OvertimeReasonCodes/OvertimeReasonCodesForm'
  ).then(module => ({
    default: module.OvertimeReasonCodesForm,
  }))
)

const TimeOffReasonCodesForm = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TimeOffReasonCodes/TimeOffReasonCodesForm'
  ).then(module => ({
    default: module.TimeOffReasonCodesForm,
  }))
)

const JobGradeListingForPolicyAssignment = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/JobGradePolicyMatrix/JobGradeListingForPolicyAssignment'
  ).then(module => ({
    default: module.JobGradeListingForPolicyAssignment,
  }))
)

const JobGradePolicyMatrix = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/JobGradePolicyMatrix/JobGradePolicyMatrix'
  ).then(module => ({
    default: module.JobGradePolicyMatrix,
  }))
)

const MobileCheckInAssignmentForm = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/MobileCheckInAssignment/MobileCheckInAssignmentForm'
  ).then(module => ({
    default: module.MobileCheckInAssignmentForm,
  }))
)

const BluetoothBeaconDevicesAssignmentForm = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/BluetoothBeaconDevicesAssignment/BluetoothBeaconDevicesAssignmentForm'
  ).then(module => ({
    default: module.BluetoothBeaconDevicesAssignmentForm,
  }))
)

const OvertimeSetting = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/OvertimeSetting/OvertimeSetting'
  ).then(module => ({
    default: module.OvertimeSetting,
  }))
)

const TMSAPAssignmentMenu = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TMSApprovalPolicy/TMSAPAssignmentMenu'
  ).then(module => ({
    default: module.TMSAPAssignmentMenu,
  }))
)

const TMSApprovalPolicyAssignment = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TMSApprovalPolicy/ApprovalPolicyAssignment'
  ).then(module => ({
    default: module.ApprovalPolicyAssignment,
  }))
)

const NewTMSApprovalPolicyAssignment = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TMSApprovalPolicy/NewApprovalPolicyAssignment'
  ).then(module => ({
    default: module.NewApprovalPolicyAssignment,
  }))
)

const TmsOtPolicyAndClaim = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TMSPolicyMatrix/TmsOtPolicyAndClaim'
  ).then(module => ({
    default: module.TmsOtPolicyAndClaim,
  }))
)
const TMSMenu = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TMSPolicyMatrix/TMSMenu'
  ).then(module => ({
    default: module.TMSMenu,
  }))
)
const TMSDailyPolicyMatrixListing = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TMSPolicyMatrix/TMSDailyPolicyMatrixListing'
  ).then(module => ({
    default: module.TMSDailyPolicyMatrixListing,
  }))
)
const TMSDailyPolicyMatrixForm = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TMSPolicyMatrix/TMSDailyPolicyMatrixForm'
  ).then(module => ({
    default: module.TMSDailyPolicyMatrixForm,
  }))
)

const TMSMonthlyPolicyMatrixListing = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TMSPolicyMatrix/TMSMonthlyPolicyMatrixListing'
  ).then(module => ({
    default: module.TMSMonthlyPolicyMatrixListing,
  }))
)
const TMSMonthlyPolicyMatrixForm = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TMSPolicyMatrix/TMSMonthlyPolicyMatrixForm'
  ).then(module => ({
    default: module.TMSMonthlyPolicyMatrixForm,
  }))
)

const TMSPeriodicDeductionPolicyMatrixListing = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TMSPolicyMatrix/TMSPeriodicDeductionPolicyMatrixListing'
  ).then(module => ({
    default: module.TMSPeriodicDeductionPolicyMatrixListing,
  }))
)
const TMSPeriodicDeductionPolicyMatrixForm = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TMSPolicyMatrix/TMSPeriodicDeductionPolicyMatrixForm'
  ).then(module => ({
    default: module.TMSPeriodicDeductionPolicyMatrixForm,
  }))
)

const TMSDeductionPolicyMatrixListing = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TMSPolicyMatrix/TMSDeductionPolicyMatrixListing'
  ).then(module => ({
    default: module.TMSDeductionPolicyMatrixListing,
  }))
)

const TMSDeductionPolicyMatrixForm = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TMSPolicyMatrix/TMSDeductionPolicyMatrixForm'
  ).then(module => ({
    default: module.TMSDeductionPolicyMatrixForm,
  }))
)

const TMSTimeOffPolicyListing = lazy(() =>
  import(
    './TimeAttendanceManagement/CompanySetting/TMSPolicyMatrix/TMSTimeOffPolicyListing'
  ).then(module => ({
    default: module.TMSTimeOffPolicyListing,
  }))
)

const HomeRoutes = [
  {
    props: { exact: true, path: '/' },
    component: <TimeAttendanceManagementListing />,
  },
  {
    props: { exact: true, path: '/tmspolicymatrix/tmsmenu' },
    component: <TMSMenu />,
  },
  {
    props: { exact: true, path: '/tmspolicymatrix/tmsotpolicyandclaim' },
    component: <TmsOtPolicyAndClaim />,
  },
  {
    props: {
      exact: true,
      path: '/tmspolicymatrix/tmsdailypolicymatrixlisting',
    },
    component: <TMSDailyPolicyMatrixListing />,
  },
  {
    props: { exact: true, path: '/tmspolicymatrix/tmsdailypolicymatrixform' },
    component: <TMSDailyPolicyMatrixForm />,
  },
  {
    props: {
      exact: true,
      path: '/tmspolicymatrix/tmsmonthlypolicymatrixlisting',
    },
    component: <TMSMonthlyPolicyMatrixListing />,
  },
  {
    props: { exact: true, path: '/tmspolicymatrix/tmsmonthlypolicymatrixform' },
    component: <TMSMonthlyPolicyMatrixForm />,
  },
  {
    props: {
      exact: true,
      path: '/tmspolicymatrix/tmsperiodicdeductionpolicymatrixlisting',
    },
    component: <TMSPeriodicDeductionPolicyMatrixListing />,
  },
  {
    props: {
      exact: true,
      path: '/tmspolicymatrix/tmsperiodicdeductionpolicymatrixform',
    },
    component: <TMSPeriodicDeductionPolicyMatrixForm />,
  },

  {
    props: {
      exact: true,
      path: '/tmspolicymatrix/tmsdeductionpolicymatrixlisting',
    },
    component: <TMSDeductionPolicyMatrixListing />,
  },
  {
    props: {
      exact: true,
      path: '/tmspolicymatrix/tmsdeductionpolicymatrixform',
    },
    component: <TMSDeductionPolicyMatrixForm />,
  },
  {
    props: {
      exact: true,
      path: '/tmspolicymatrix/tmstimeoffpolicylisting',
    },
    component: <TMSTimeOffPolicyListing />,
  },
  {
    props: { exact: true, path: '/TimeAttendanceManagementListing' },
    component: <TimeAttendanceManagementListing />,
  },
  {
    props: {
      exact: true,
      path: '/TimeAttendanceManagement/CompanySetting/TMSCompanySetting',
    },
    component: <TMSCompanySetting />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/ShiftAssignment/ShiftAssignmentForm',
    },
    component: <ShiftAssignmentForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/ShiftPatternAssignment/ShiftPatternAssignmentForm',
    },
    component: <ShiftPatternAssignmentForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/ShiftGroupAssignment/ShiftGroupAssignmentForm',
    },
    component: <ShiftGroupAssignmentForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/ShiftGroupAssignment/ShiftPatternAssignmentForm',
    },
    component: <ShiftPatternAssignmentForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/TimeOffPolicyAssignment/TimeOffPolicyAssignmentForm',
    },
    component: <TimeOffPolicyAssignmentForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/OvertimeReasonCodes/OvertimeReasonCodesForm',
    },
    component: <OvertimeReasonCodesForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/TimeOffReasonCodes/TimeOffReasonCodesForm',
    },
    component: <TimeOffReasonCodesForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/JobGradePolicyMatrix/JobGradeListingForPolicyAssignment/:companyID',
    },
    component: <JobGradeListingForPolicyAssignment />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/JobGradePolicyMatrix/JobGradePolicyMatrix/:companyID/:jobGradeID',
    },
    component: <JobGradePolicyMatrix />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/MobileCheckInAssignment/MobileCheckInAssignmentForm',
    },
    component: <MobileCheckInAssignmentForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/BluetoothBeaconDevicesAssignment/BluetoothBeaconDevicesAssignmentForm',
    },
    component: <BluetoothBeaconDevicesAssignmentForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/OvertimeSetting/OvertimeSetting',
    },
    component: <OvertimeSetting />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/TMSApprovalPolicy/TMSAPAssignmentMenu',
    },
    component: <TMSAPAssignmentMenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/TMSApprovalPolicy/TMSApprovalPolicyAssignment/overtime',
    },
    component: <TMSApprovalPolicyAssignment type="OverTime" />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/TMSApprovalPolicy/TMSApprovalPolicyAssignment/timeoff',
    },
    component: <TMSApprovalPolicyAssignment type="TimeOff" />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/TMSApprovalPolicy/TMSApprovalPolicyAssignment/changeshiftrequest',
    },
    component: <TMSApprovalPolicyAssignment type="ChangeShiftRequest" />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/TMSApprovalPolicy/TMSApprovalPolicyAssignment/batchovertimeentry',
    },
    component: <TMSApprovalPolicyAssignment type="BatchOvertimeEntry" />,
  },
  {
    props: {
      exact: true,
      path:
        '/TimeAttendanceManagement/CompanySetting/TMSApprovalPolicy/TMSApprovalPolicyAssignment/attendancecorrectionrequest',
    },
    component: (
      <NewTMSApprovalPolicyAssignment type="AttendanceCorrectionRequest" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PeriodicAllowanceType/PeriodicAllowanceTypeListing',
    },
    component: <PeriodicAllowanceTypeListing />,
  },
]

export default HomeRoutes
